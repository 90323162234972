import React from 'react';
import {Link} from 'gatsby'
import Page from '../../components/pages/Page';

const i18n = {
  titleId: 'about',
  content: (
    <>
      <p>Hallo!</p>
      <p>Ik ben Lucas, een web developer uit Antwerpen.</p>
      <p>Met een bachelor diploma KMO-management op zak, en enkele jaren ervaring als aankoper, besefte ik al snel dat ik een andere richting uit wilde in mijn leven. Ik vond mijn passie in web development en deed een carrièreswitch waar ik nog geen seconde spijt van heb gehad. Vandaag de dag werk ik als web development consultant bij Pàu, te Antwerpen.</p>
      <p>Dagelijks engageer ik mezelf om nieuwe technologieëen te leren en mijn skills als webdeveloper te verbeteren. In mijn vrije tijd gebruik ik mijn kennis om kleine tot middelgrote ondernemingen te helpen met hun online zichtbaarheid als zelfstandige in bijberoep.</p>

      <p>Mijn passie ligt bij Javascript development, zowel front-end (voornamelijk ReactJs) als back-end (Node JS). Daarnaast heb ik kennis van conversie optimalisatie, SEO, performance optimalisatie en (online) marketing.</p>
      <p>Indien je graag meer wil weten, <Link to="/nl/contact">contacteer mij</Link>.</p>


      <p>Grt!</p>
    </>
  ),
  description: `
    Web Developer te Antwerpen
  `
};

export default (props) => <Page i18n={i18n} {...props} />;

